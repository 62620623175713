/*=======================================
				Banner
=======================================*/

$sub-banner-bgcolor: #161f2a;
$sub-banner-background-small: url("/images/sub-banner.jpg");
$sub-banner-background: $sub-banner-background-small;

// Banner Heading
$sub-banner-h1-padding-small: rem-calc(20 0 10 0);
$sub-banner-h1-padding-medium: rem-calc(40 0 10 0);
$sub-banner-h1-padding-large: rem-calc(50 0 10 0);
$sub-banner-h1-text-color: #fff;
$sub-banner-h1-font-size: map-get(map-get($header-sizes, medium), h1);
$sub-banner-h1-font-weight: $global-weight-normal;
$sub-banner-h1-text-multiply: .8;
$sub-banner-h1-text-border: none;
$sub-banner-h1-after-display: none;		// For double border display
$sub-banner-h1-border-spacing: 0;		// For double border margin

.sub-banner{
	padding: $sub-banner-h1-padding-small;
	min-height:rem-calc(80);
	background-color: $sub-banner-bgcolor;
	background-image: $sub-banner-background-small;
	background-size: cover;
	background-position: 50% 0;
	background-repeat: no-repeat;

	#sub-banner-container > * {
		@extend h1;
		margin-bottom: $sub-banner-h1-border-spacing;
		padding: $sub-banner-h1-padding-small;
		border: $sub-banner-h1-text-border;
		color: $sub-banner-h1-text-color;
		font-size: $sub-banner-h1-font-size * $sub-banner-h1-text-multiply;
		font-weight: $sub-banner-h1-font-weight;

		// Clear
		&::after{
			display: $sub-banner-h1-after-display
		}
	}

	@include breakpoint(medium){
		background-image: $sub-banner-background;

		#sub-banner-container > * {
			padding: $sub-banner-h1-padding-medium;
			color: $sub-banner-h1-text-color;
			font-size: $sub-banner-h1-font-size;
		}
	}

	@include breakpoint(large){
		min-height:rem-calc(150);
		#sub-banner-container > * {
			padding: $sub-banner-h1-padding-large;
		}
	}
}

#page-id-8 .sub-banner {
	text-align: center;
}
