// Sort Products Container
form .product_sort_dropdown_container{
	label{
		display: inline-block;
		vertical-align: middle;
		margin-right: rem-calc(10);
		line-height: 33px;
		font-size: rem-calc(18);
		font-weight: 600;
		color: #000;
	}
	input[type="submit"].buttons{
		display: none;
	}
	#SortBox{
		max-width: rem-calc(300);
		display: inline-block;
		vertical-align: middle;
		margin-bottom: rem-calc(20);
	}
}

// List Products
.products-list{
	a{
		text-decoration: none;
		margin-bottom: rem-calc(20);
		display: block;
	}

	.product_result_container{
		background: #fff;
		max-width: rem-calc(280);
		text-align: center;
		margin: 0 auto rem-calc(30);
		border: rem-calc(6) solid #fff;
		box-shadow: inset 0 0 9px 1px #f4f4f4, 0px 1px 1px 0px rgba(0, 0, 0, 0.22);
		img{
			display: block;
			margin: 0 auto rem-calc(10);
			max-height: rem-calc(250);
		}

		.product_header{
			color: #000;
			font-size: rem-calc(24);
			font-weight: 600;
			line-height: 1.2;
		}

		.product_cost{
			margin-top: 0;
		}

		.product_cost_view{
			color: #80c245;
			font-size: rem-calc(24);
			font-weight: 600;
		}

		@include breakpoint(large){
			margin-bottom: 0;
		}

		// Cancels out include.css.php styles associated with products
		.product_imagecontainer{
			min-height: 0;
			display: block;
			vertical-align: initial;
		}
		.product_cost_box_standard{
			background: transparent;
			border: 0;
		}
		.product_cost{
			height: auto;
		}
	}
}

// Global MYCE Pagination Style
.paged_results{
	@include grid-column(12);
	margin-bottom: rem-calc(20);
	font-weight: 600;
	.page_selected{
		background: #80c245;
		color: #fff;
		padding: rem-calc(0 3);
	}
	.page_notselected{
		text-decoration: none;
	}
	.see-all-products{
		margin-left: rem-calc(15);
		text-decoration: none;
	}
}

// Product Detail Page
.product-img-container{
	background: #fff;
	margin: rem-calc(0 0 20 0);
    text-align: center;
    border: rem-calc(6) solid #fff;
    box-shadow: inset 0 0 0 10px rgba(255, 255, 255, 0.45);
    li{
    	padding-bottom: rem-calc(0);
    }
}
.product-img-thumb-container .row{
	background: #fff;
	margin: rem-calc(0 0 20 0);
    text-align: center;
    border: rem-calc(6) solid #fff;
    box-shadow: inset 0 0 9px 1px #f4f4f4, 0px 1px 1px 0px rgba(0,0,0,0.22);
}
.product-name h1{
	font-size: rem-calc(36);
	margin-bottom: rem-calc(10);
}
.product-details-header{
	@extend h1;
}
.product-price{
	font-weight: 700;
	color: $secondary-color;
	font-size: rem-calc(18);
	margin-bottom: rem-calc(10);
}
.product_variation_productcode{
	font-style: normal;
	font-weight: 300;
	color: #505050;
}
.product-list-description{
	color: #57513f;
	font-size: rem-calc(18);
	font-weight: 700;
	margin: rem-calc(0 0 20 0);
	li{
		list-style: none;
		background: url('/images/icon-leaves.png') center left no-repeat;
		padding: rem-calc(5 0 5 28);
	}
}
.quantity{
	font-size: rem-calc(24);
	margin: rem-calc(20 0);
	color: $secondary-color;
	input{
		display: inline-block;
		margin-left: rem-calc(4);
		width: rem-calc(65);
		background: #eee;
		border: 1px solid #d4d4d4;
		border-radius: rem-calc(5);
		box-shadow: none;
		font-size: rem-calc(24);
		color: $secondary-color;
		&:hover{
			cursor: pointer;
		}
	}
}
form .product button.submit{
	padding: rem-calc(15 30);
	margin: rem-calc(0 0 30 0);
	text-shadow: 0 1px 0 rgba(0, 0, 1, 0.1);
	font-size: rem-calc(18);
	font-weight: 700;
	color: #fff;
	background-color: $secondary-color;
	border: 1px solid #460a0a;
	transition: background-color .2s;
	&:hover{
		background-color: darken($secondary-color, 10%);
	}
}
.us-only{
	display: inline-block;
	margin-left: rem-calc(10);
	color: #990000;
	font-weight: 700;
}
.product-variations{
	background: #eee;
	border: 1px solid #c1c1c1;
	border-bottom: 0;
	.variation{
		padding: rem-calc(20);
		border-bottom: 1px solid #c1c1c1;
		transition: .1s;
		display: flex;
		justify-content: space-between;
		align-items: center;
		&:hover{
			background: $primary-color;
		}
		input{
			margin: rem-calc(2 8 0 0);
			pointer-events: none;
		}
		.variation-name{
			font-size: rem-calc(18);
			font-weight: 700;
			color: #000;
			line-height: 1.2;
			display: flex;
		}
		.variation-number{
			font-size: rem-calc(18);
			font-weight: 300;
			color: #505050;
			line-height: 1.2;
			margin-left: rem-calc(20);
		}
		.variation-price{
			color: #000;
			font-size: rem-calc(18);
			font-weight: 700;
			margin-left: rem-calc(20);
		}
		@include breakpoint(medium){
			.variation-price{
				display: inline-block;
			}
		}
	}
}

.product-in-box{
	border-top: 1px solid rgba(0,0,0,.2);
	padding: rem-calc(30 0);
	.product-info{
		p{
			font-size: rem-calc(16);
			font-family: $header-font-family;
		}
	}
	img{
		display: block;
		margin: 0 auto rem-calc(20);
	}
	.heading{
		width: 100%;
		color: $secondary-color;
		font-size: rem-calc(30);
	}
	@include breakpoint(medium){
		display: flex;
  		align-items: center;
		.product-info{
			display: flex;
  			flex-direction: column;
  			justify-content: center;
			padding: rem-calc(0 0 0 30);
		}
		img{
			margin: 0;
			max-width: rem-calc(200);
		}
	}
}

.alert-box{
	border-style: solid;
	border-width: 1px;
	display: block;
	font-size: rem-calc(16);
	font-weight: 400;
	margin-bottom: 1.11111rem;
	padding: .77778rem 1.33333rem .77778rem .77778rem;
	position: relative;
	transition: opacity 300ms ease-out;
	background-color: #84c042;
	border-color: #72a637;
	color: #fff;
	&.success{
		background-color: #43AC6A;
	    border-color: #3a945b;
	    color: #fff;
	}
	&.alert{
		background-color: $alert-color;
	    border-color: darken($alert-color, 10%);
	    color: #fff;
	}
	.close{
		display: none;
	}
}
