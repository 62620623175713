@charset "UTF-8";

@import "partials/foundation-settings";

/*=======================================
        Imports
=======================================*/

// Normalize 3.0.3
@import "vendor/normalize/normalize";

/**
 * Foundation for Sites by ZURB
 * Version 6.2.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

// Sass utilities
@import 'vendor/foundation/util/util';

// Global variables and styles
@import 'vendor/foundation/global';

// Components
@import 'vendor/foundation/grid/grid';
@import 'vendor/foundation/typography/typography';
@import 'vendor/foundation/forms/forms';
@import 'vendor/foundation/components/visibility';
@import 'vendor/foundation/components/float';
@import 'vendor/foundation/components/button';
// @import 'vendor/foundation/components/button-group';
// @import 'vendor/foundation/components/accordion-menu';
// @import 'vendor/foundation/components/accordion';
// @import 'vendor/foundation/components/badge';
// @import 'vendor/foundation/components/breadcrumbs';
@import 'vendor/foundation/components/callout';
@import 'vendor/foundation/components/close-button';
@import 'vendor/foundation/components/drilldown';
@import 'vendor/foundation/components/dropdown-menu';
@import 'vendor/foundation/components/dropdown';
@import 'vendor/foundation/components/flex';
@import 'vendor/foundation/components/flex-video';
@import 'vendor/foundation/components/label';
// @import 'vendor/foundation/components/media-object';
@import 'vendor/foundation/components/menu';
@import 'vendor/foundation/components/menu-icon';
// @import 'vendor/foundation/components/off-canvas';
// @import 'vendor/foundation/components/orbit';
// @import 'vendor/foundation/components/pagination';
// @import 'vendor/foundation/components/progress-bar';
// @import 'vendor/foundation/components/reveal';
// @import 'vendor/foundation/components/slider';
// @import 'vendor/foundation/components/sticky';
// @import 'vendor/foundation/components/switch';
@import 'vendor/foundation/components/table';
// @import 'vendor/foundation/components/tabs';
@import 'vendor/foundation/components/title-bar';
@import 'vendor/foundation/components/top-bar';
@import 'vendor/foundation/components/thumbnail';
// @import 'vendor/foundation/components/tooltip';

@mixin foundation-everything($flex: true){
  @if $flex{
    $global-flexbox: true !global;
  }

  @include foundation-global-styles;
  @if not $flex{
    @include foundation-grid;
  }
  @else{
    @include foundation-flex-grid;
  }
  @include foundation-typography;
  @include foundation-forms;
  @include foundation-button;
  // @include foundation-accordion;
  // @include foundation-accordion-menu;
  // @include foundation-badge;
  // @include foundation-breadcrumbs;
  // @include foundation-button-group;
  // @include foundation-callout;
  @include foundation-close-button;
  @include foundation-menu;
  @include foundation-menu-icon;
  @include foundation-drilldown-menu;
  @include foundation-dropdown;
  @include foundation-dropdown-menu;
  @include foundation-flex-video;
  @include foundation-label;
  // @include foundation-media-object;
  // @include foundation-off-canvas;
  // @include foundation-orbit;
  // @include foundation-pagination;
  // @include foundation-progress-bar;
  // @include foundation-slider;
  // @include foundation-sticky;
  // @include foundation-reveal;
  // @include foundation-switch;
  @include foundation-table;
  // @include foundation-tabs;
  @include foundation-thumbnail;
  @include foundation-title-bar;
  // @include foundation-tooltip;
  @include foundation-top-bar;
  @include foundation-visibility-classes;
  @include foundation-float-classes;

  @if $flex{
    @include foundation-flex-classes;
  }
}

@include foundation-everything(true);

@import "partials/fonts";

// Flexslider 2.4.0
@import "vendor/flexslider/flexslider";

// Fancybox 2.1.5
@import "vendor/fancybox/jquery.fancybox";

// ImageLightBox by http://osvaldas.info/image-lightbox-responsive-touch-friendly
@import "vendor/imagelightbox/imagelightbox";

// Architecture Settings
@import "partials/hlm-mixins";
@import "partials/hlm-functions";

// HLM Base Styles
@import "partials/base";
@import "partials/articles";


/*=======================================
          HLM Modules
=======================================*/
// Header Options
@import "modules/header/header.nav-contain";
// @import "modules/header/header.nav-top";
// @import "modules/header/header.nav-under";

// Banner Options
// @import "modules/banner/banner.form";
@import "modules/banner/banner.full-width";
// @import "modules/banner/banner.sliding-gallery-banners";

// Callout Options
// @import "modules/callouts/callout.bar";
// @import "modules/callouts/callout.content";
// @import "modules/callouts/callout.footer";
// @import "modules/callouts/callouts.adams";
// @import "modules/callouts/callouts.bagley";
// @import "modules/callouts/callouts.cass";

// Gallery Options
@import "modules/gallery/gallery.ImageLightbox";

// Testimonial Options
// @import "modules/testimonials/testimonial.single";
@import "modules/testimonials/testimonials.slider";

// Social Link Options
@import "modules/social/social.round-links";
// @import "modules/social/social.vertical-bar";

// Footer Options
// @import "modules/footer/footer.quarter-half-quarter";
@import "modules/footer/footer.stacked";

// HLM Sections
@import "sections/header";
@import "sections/layouts";
@import "sections/footer";
@import "sections/page-home";
@import "sections/sub-banner";

// HLM Other
@import "partials/coupons";
@import "partials/blog-grid";
@import "partials/events";
@import "partials/top-bar";
@import "partials/form-controls";
@import "partials/subnav";
@import "partials/account";
// @import "partials/offcanvas-hlm";
@import "partials/products";
@import "partials/sml";

/*=======================================
      Custom Site Styles
=======================================*/

.product-grid{
  background: #fff;
  text-align: center;
  .heading.indulgence{
    position: relative;
    padding: rem-calc(20);
    background: url('/images/header-bg.png') center center no-repeat;
    font-size: rem-calc(36);
    text-align: center;

  }

  .message{
    font-size: rem-calc(20);
    font-family: $header-font-family;
    text-align: center;
    color: #3b3b3b;
  }

  a{
    display: block;
    text-decoration: none;
    padding: rem-calc(20 0);
    border-bottom: 1px solid #d9d9d9;
    transition: background .3s ease-in;

    &:hover{
      background: transparentize($primary-color, .4);
    }

    .heading{
      @extend h2;
      font-size: rem-calc(34);
      text-transform: uppercase;
      color: $secondary-color;
    }
  }

  .column:last-child a{
    border-bottom: 0;
  }

  @include breakpoint(medium){
    .heading.indulgence{
      margin: rem-calc(0 0 10);
    }

    .medium-up-2.row{
      .column:nth-child(2n + 1){
        border-right: 1px solid #d9d9d9;
      }
      .column:nth-last-child(-n+2):nth-child(odd) a{
        border-bottom: 0;
      }
    }

   .large-up-3.row{
      .column{
        a{
          border-bottom: 1px solid #d9d9d9;
        }
      }
      .column{
        &:nth-last-child(5) a, &:nth-child(6) a{
          border-bottom: 0;
        }
      }
    }
  }

  @include breakpoint(large){

    a{
      padding: rem-calc(40 0 50);
    }

    .heading.indulgence{
      font-size: rem-calc(54);
    }

    .message{
      font-size: rem-calc(24);
    }
    .large-up-3.row {
      .column:nth-child(2n + 2), .column:nth-child(4) a{
        border: 0;
      }
      .column:nth-child(2n + 1){
        border-right: 0;
      }
      .column:nth-child(3n + 2){
        border-left: 1px solid #d9d9d9;
        border-right: 1px solid #d9d9d9;
      }
      .column{
        &:nth-child(1), &:nth-child(2), &:nth-child(3){
          a{
            border-bottom: 1px solid #d9d9d9;
          }
        }
      }
    }
  }
}

#page-id-1 .product-grid {
  padding: rem-calc(30 0);
  @include breakpoint(large){
    padding: rem-calc(60 0 100);
  }
}

.testimonial-grid{
  padding: rem-calc(10 0);
  background: #fff;
  .heading{
    position: relative;
    background: url('/images/header-bg.png') center center no-repeat;
    padding: rem-calc(20);
    font-size: rem-calc(36);
    text-align: center;

  }

  .message{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: rem-calc(30);
    font-size: rem-calc(24);
    text-align: center;
    color: #807e7e;

    .button{
      margin: rem-calc(0 0 0 20);
    }
  }

  @include breakpoint(large){
    padding: rem-calc(50 0);

    .heading{
      font-size: rem-calc(54);
    }

    .message{
      font-size: rem-calc(30);
    }
  }
}

.callout-visit{
  display: block;
  margin: 0 auto rem-calc(30);
  padding: rem-calc(60 30);
  max-width: rem-calc(360);
  text-align: center;
  text-decoration: none;
  background-color: $secondary-color;
  background-image: url('/images/callout-visit-bg.png');
  transition: .3s ease-in;
  &:hover{
    background-color: darken($secondary-color, 15%);
  }

  .heading{
    color: #fff;
    font-size: rem-calc(36);
  }

  .message{
    font-weight: 700;
    margin-bottom: rem-calc(20);
  }
}

// Mailchimp Custom Styling
#mc_embed_signup{
  margin: 0 auto;
  max-width: rem-calc(360);
  background: $secondary-color;
  padding: rem-calc(20 30 30);
  margin-bottom: rem-calc(30);
  h2{
    color: #fff;
    font-size: rem-calc(24);
    margin-bottom: rem-calc(10);
  }
  label{
    display: none;
  }
  .mc-field-group{
    margin: 0;
  }
}

// Facebook Page Plugin
.fb-frame{
  margin: 0 auto;
  display: block;
  max-width: rem-calc(360);
}
